<template>
  <div id="review-list">
    <!-- dialog -->
    <v-card>
      <delete-confirmation
        v-model="isDeleteDialogActive"
        :selected-item="selectedItem"
        @delete-confirmation="submitDelete"
        @refetch-data="refreshPage"
      ></delete-confirmation>

      <task-add-edit
        v-if="isTaskFormActive"
        :is-task-form-active="isTaskFormActive"
        :patient-task-d-t-o="selectedItem"
        :is-edit="true"
        @update-dialog="updateDialog"
      ></task-add-edit>
    </v-card>

    <v-dialog
      v-if="selectedForm != null"
      v-model="renderDialog"
      persistent
      max-width="75%"
    >
      <div>
        <v-card>
          <v-card-text>
            <div>
              <v-row
                justify="center"
              >
                <v-col
                  md="auto"
                >
                  <v-btn
                    color="error"
                    @click="updateRenderDialog('render')"
                  >
                    Close
                  </v-btn>
                </v-col>
                <v-col
                  md="auto"
                >
                  <router-link
                    :to="{ name: 'site-patient-chart', params: { patientId: selectedForm.patientId } }"
                  >
                    <v-btn
                      color="success"
                    >
                      View Chart
                    </v-btn>
                  </router-link>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  md="auto"
                >
                  <v-btn
                    color="success"
                    @click="updateDialog('Status')"
                  >
                    Create Task
                  </v-btn>
                </v-col>
                <v-col
                  md="auto"
                >
                  <v-btn
                    color="primary"
                    @click="submitReview('Signed'), renderDialog = !renderDialog"
                  >
                    Approve
                  </v-btn>
                </v-col>
                <v-col
                  md="auto"
                >
                  <v-btn
                    color="warning"
                    @click="submitReview('Rejected'), renderDialog = !renderDialog"
                  >
                    Reject
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
        <v-card>
          <form-render
            :form-data="selectedForm.formStyle"
            :form-input-data="selectedForm.formInput"
            :patient-form-d-t-o="selectedForm"
            :is-edit="isEdit"
            :toolbar="false"
            @update-dialog="updateRenderDialog"
            @refetch-data="refreshPage"
          ></form-render>
        </v-card>
      </div>
    </v-dialog>

    <v-card>
      <v-toolbar>
        <!-- search -->
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            :prepend-inner-icon="icons.mdiMagnify"
            class="me-3 mb-4"
          >
          </v-text-field>

          <div class="d-flex align-center flex-wrap">
            <v-btn
              v-model="isTaskFormActive"
              color="primary"
              class="mb-4 me-3"
              @click="updateDialog(null), isEdit = true"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add New Task</span>
            </v-btn>
          </div>
        </v-card-text>

        <!-- Dynamic Type Tabs -->
        <template v-slot:extension>
          <v-tabs
            v-model="currentTab"
            icons-and-text
            grow
            show-arrows
            :next-icon="icons.mdiArrowRight"
            :prev-icon="icons.mdiArrowLeft"
            background-color="primary"
            dark
          >
            <v-tab
              v-for="(type) in reviewType"
              :key="type"
              @change="currentReviewType = type"
            >
              {{ type }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="reviewListColumns"
        :items="reviewListTable"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
      >
        <template #[`item.patient.displayName`]="{item}">
          <td
            class="text-xs-left color-corner"
            :class="item.signStatus.toString()"
          >
            <router-link
              :to="{ name: 'site-patient-chart', params: { patientId: item.patient.id } }"
            >
              <v-btn
                color="primary"
                text
              >
                {{ item.patient.displayName }}
              </v-btn>
            </router-link>
          </td>
        </template>
        <template #[`item.display`]="{item}">
          <v-btn
            v-if="item.patientForm !== null && item.patientForm !== undefined"
            v-model="renderDialog"
            color="primary"
            text
            @click="selectedItem = item, selectedForm = item.patientForm, updateRenderDialog('render'), isEdit = false"
          >
            <v-icon
              class="me-2"
            >
              {{ icons.mdiCheckAll }}
            </v-icon>
            {{ item.display }}
          </v-btn>
          <span v-else>
            {{ item.display }}
          </span>
        </template>
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="submitReview('Signed')"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Approve</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="submitReview('Rejected')"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  <span>Reject</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isDeleteDialogActive = !isDeleteDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiFileDocumentEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
  mdiFormTextboxPassword,
  mdiMagnify,
  mdiCheckAll,
} from '@mdi/js'

import taskService from '@/services/TaskService'
import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import useTaskService from '@/store/useTaskService'
import CommonList from '@/store/CommonService'
import TaskAddEdit from '../task/TaskAddEdit.vue'
import FormRender from '@/apps/form/FormRender.vue'

export default {
  components: { DeleteConfirmation, TaskAddEdit, FormRender },
  mounted() {
    this.fetchTaskTypes()
    this.fetchPatientReviewsByType()
  },
  setup() {
    const TASK_APP = 'app-task'

    // Register module
    if (!store.hasModule(TASK_APP)) store.registerModule(TASK_APP, taskService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TASK_APP)) store.unregisterModule(TASK_APP)
    })

    const {
      reviewListTable,
      reviewListColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedRows,
      fetchTaskTypes,
      taskTypeList,
      fetchPatientReviewsByType,
      currentReviewType,
      reviewType,
      postPatientReview,
    } = useTaskService()

    const { readStatus } = CommonList()

    const isEdit = ref(false)
    const isNew = ref(false)
    const selectedItem = ref({})
    const isDeleteDialogActive = ref(false)
    const isTaskFormActive = ref(false)
    const currentTab = ref(6)
    const renderDialog = ref(false)
    const selectedForm = ref(null)

    return {
      fetchPatientReviewsByType,
      fetchTaskTypes,
      taskTypeList,
      reviewType,
      reviewListTable,
      reviewListColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedRows,
      isTaskFormActive,
      selectedItem,
      isEdit,
      isNew,
      isDeleteDialogActive,
      readStatus,
      currentTab,
      currentReviewType,
      renderDialog,
      selectedForm,
      postPatientReview,
      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiFileDocumentEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
        mdiFormTextboxPassword,
        mdiMagnify,
        mdiCheckAll,
      },

    }
  },

  methods: {
    log(msg, item) {
      console.log(msg, item)
    },
    selectItem(item) {
      this.selectedItem = item
      console.log(this.selectedItem)
    },
    submitDelete(item) {
      console.log(item)
    },
    submitReview(signStatus) {
      console.log('Submit Review', signStatus, this.selectedItem)
      this.selectedItem.signStatus = signStatus
      this.postPatientReview(this.selectedItem)
    },
    refreshPage() {
      this.selectedItem = ref()
      this.fetchPatientReviewsByType()
      this.$watch('reviewListTable', () => {
        console.log(this.reviewListTable)
      })
    },
    renderForm(formData) {
      this.selectedForm = formData
      this.selectedItem = formData.formInput
    },
    updateRenderDialog(dialog) {
      console.log(this.selectedForm)
      console.log(this.renderDialog)
      if (dialog === 'render') {
        if (this.renderDialog) {
          this.selectedForm = {}
          this.selectedItem = {}
        }
        if (this.selectedForm == null) {
          this.renderDialog = false
        }
        this.renderDialog = !this.renderDialog
      }
    },
    updateDialog(status) {
      console.log('Status', this.selectedForm)
      console.log('Dialog', this.isTaskFormActive)

      this.selectedItem = {
        patientId: this.selectedForm.patientId,
        patient: this.selectedForm.patient,
        status: 'New',
        startDate: new Date().toISOString().split('T')[0],
        dueDate: new Date().toISOString().split('T')[0],
        taskName: this.selectedForm.formStyle.formName,
        taskDescription: this.selectedForm.formStyle.formName,
        patientFormId: this.selectedForm.id,
        isActive: true,
      }

      if (status == null) {
        this.selectedItem = {}
      }
      this.isTaskFormActive = !this.isTaskFormActive
      console.log('Selected Item', this.selectedItem)
    },
  },
}
</script>

<style lang="scss">

.color-corner {
  position: relative;
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
}
.New:before {
  border-left: 10px solid #48ff30;
  border-top: 10px solid #48ff30;
}
.Rejected:before {
  border-left: 10px solid #ff3030;
  border-top: 10px solid #ff3030;
}
.Signed:before {
  border-left: 10px solid #bf1cce;
  border-top: 10px solid #bf1cce;
}
</style>
